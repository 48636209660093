<template>
    <div>
        <div class="unit-topbar">
            <div class="topbar-container">
                <div class="topbar-container__title">
                    <span>{{ testTypeName }}</span> |
                    <span>{{ courseName }}</span> |
                    <template v-if="unitName !=''">
                        <span>{{ unitName }}</span> |
                    </template>
                    <span>（共{{ timuNum }}道题）</span>
                </div>
                <div class="topbar-container__sidebar">
                    <div class="dialog-title__persons">
                        <img :src="avatarImg" alt="" style="border-radius: 50%">
                        <span>{{ userName }}</span>
                    </div>
                    <el-button class="submit-btn" @click="actionSave" v-if="kaoshi_status == 0">
                        <span>交</span><span>卷</span>
                    </el-button>
                    <a href="javascript:;" @click="closeDialog">返回</a>
                </div>
            </div>
        </div>
        <div class="left-timer online-time">
            <div class="online-time__title">
                <img :src="cloakImg" alt="">
                <span style="color: #1CA3A3">在线时间</span>
            </div>
            <span class="left-time" style="color: #1CA3A3">{{ jsq_times }}</span>
        </div>
        <div class="timer online-time">
            <div class="online-time__title">
                <img :src="cloakSrc" alt="">
                <span>剩余时间</span>
            </div>
            <span class="right-time">{{ djs_msg }}</span>
        </div>
        <div class="unit" ref="scrollRef">
            <div class="test-result" v-if="kaoshi_status == 1">
                <div class="result-info">
                    <span class="info-text">测试结果</span>
                    <div class="info-img">
                        <div class="achievement">
                            <span class="test-text">测试成绩</span>
                            <span class="score">{{ scorse }}</span><span>分</span>
                            <div class="achievement-questions">
                                <span class="right-check">答对<span>{{ rightCount }}</span>题</span>
                                <span class="right-error">答错<span>{{ errorCount }}</span>题</span>
                            </div>
                            <div class="time-box">
                                <span class="used-time">使用时间：{{ yongshi_time }}</span>
                            </div>
                        </div>
                        <div class="proposal">
                            <span class="test-text">学习建议</span>
                            <div class="gain">
                                <img src="@/assets/images/study/gold-logo.png" alt="" />
                                <span class="gold">{{ baoshi_num }}</span>
                            </div>
                            <div class="tuijian">
                                <span class="test-text text">推荐</span>
                                <el-button class="error-word eCheck" @click="cuociGonggu">错词巩固</el-button>
                                <el-button class="test-again" @click="againTest">再次测试</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="test-questions">
                    <div class="questions-btn">
                        <el-button :class="['all', isShowType == 0 ? 'eCheck' : '']" @click="showDaan(0)">错误试题
                        </el-button>
                        <el-button :class="['all', isShowType == 1 ? 'eCheck' : '']" @click="showDaan(1)">
                            全部试题
                        </el-button>
                    </div>
                    <div class="questions-right">
                        <span class="right-check" style="display:none">答对<span>{{ rightCount }}</span>题</span>
                        <span class="right-error" style="display:none">答错<span>{{ errorCount }}</span>题</span>
                        <el-button class="afresh" @click="againTest">重新测试</el-button>
                    </div>
                </div>
            </div>
            <div class="unit-test" ref="unitTest">
                <template v-if="kaoshi_status == 0">
<!--                    <div class="Etranslate unit-test-item" v-show="tab_show==1">-->
<!--                        <div class="item-title">-->
<!--                            <span>【英译中（Tab跳转下一题，四个选项快捷键为1/2/3/4）】</span>-->
<!--                        </div>-->
<!--                        <div class="item-questions">-->
<!--                            <template v-if="nianji_level == 0">-->
<!--                                <ul :class="[-->
<!--                                    'item-questions-info',-->
<!--                                    { itemActive: tab_index == index },-->
<!--                                ]" v-for="(item, index) in yyz_lists_daan" :key="index" @click="clkItem(index)">-->
<!--                                    <li class="info-title">-->
<!--                                        <span class="t-num">{{ index + 1 }}.</span><span class="t-text t-english">{{-->
<!--                                            item.name-->
<!--                                        }}</span>-->
<!--                                    </li>-->
<!--                                    <li class="info-choose" v-for="(c_item, c_index) in item.shiyi_dx" :key="c_index">-->
<!--                                        <label>-->
<!--                                            <div class="input-box">-->
<!--                                                <input type="radio" :name="'zyy' + item.name" id="" @click="-->
<!--                                                    xuanze_yyz(-->
<!--                                                        index,-->
<!--                                                        c_item,-->
<!--                                                        c_index-->
<!--                                                    )-->
<!--                                                " :ref="index + '_' + c_index" />-->
<!--                                                &lt;!&ndash;<i class="i-radio"></i>&ndash;&gt;-->
<!--                                                <span class="i-radio" v-if="c_item.is_xz"></span>-->
<!--                                            </div>-->
<!--                                            <div :class="[-->
<!--                                                'choose',-->
<!--                                                c_item.is_xz-->
<!--                                                    ? 'info-choose__check'-->
<!--                                                    : '',-->
<!--                                            ]">-->
<!--                                                <span class="choose-item"></span>-->
<!--                                                <span class="choose-answer">{{-->
<!--                                                        c_item.text-->
<!--                                                    }}</span>-->
<!--                                            </div>-->
<!--                                        </label>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </template>-->
<!--                            &lt;!&ndash; 幼儿 &ndash;&gt;-->
<!--                            <template v-if="nianji_level == 1">-->
<!--                                <div class="item-questions-info" :class="[-->
<!--                                    'item-questions-info',-->
<!--                                    { itemActive: tab_index == index },-->
<!--                                ]" v-for="(item, index) in yyz_lists_daan" :key="index" @click="clkItem(index)">-->
<!--                                    <div class="info-title">-->
<!--                                        <span class="t-num">{{ index + 1 }}.</span>-->
<!--                                        <span class="t-text t-english">{{-->
<!--                                                item.name-->
<!--                                            }}</span>-->
<!--                                    </div>-->
<!--                                    <ul class="info-select">-->
<!--                                        <li v-for="(c_item, c_index) in item.shiyi_dx" :key="c_index">-->
<!--                                            &lt;!&ndash;<label style="cursor: pointer">-->
<!--                                        <img :class="[c_item.is_xz == 1  ? 'baby-checked' : '']" :src="audioUrl + c_item.img_link" alt="">-->
<!--                                        <div class="baby-item">-->
<!--                                            <input-->
<!--                                                    type="radio"-->
<!--                                                    id=""-->
<!--                                                    value=""-->
<!--                                                    :name="'zyy' + item.name"-->
<!--                                                    @click="-->
<!--                                                        xuanze_yyz(-->
<!--                                                            index,-->
<!--                                                            c_item,-->
<!--                                                            c_index-->
<!--                                                        )-->
<!--                                                    "-->
<!--                                                    :ref="index + '_' + c_index"-->
<!--                                            />-->
<!--                                            <div class="choose">-->
<!--                                                <span class="choose-item">-->
<!--                                                    <span class="choose-item-text"></span>-->
<!--                                                </span>-->
<!--                                                <span class="choose-answer">{{c_item.text}}</span>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </label>&ndash;&gt;-->
<!--                                            <img :class="[c_item.is_xz == 1 ? 'baby-checked' : '']"-->
<!--                                                 :src="audioUrl + c_item.img_link" alt="" @error="imgError"-->
<!--                                                 @click="xuanze_yyz(index, c_item, c_index)">-->
<!--                                            <label>-->
<!--                                                <div class="input-box">-->
<!--                                                    <input type="radio" :name="'zyy' + item.name"-->
<!--                                                           @click="xuanze_yyz(index, c_item, c_index)"-->
<!--                                                           :ref="index + '_' + c_index" />-->
<!--                                                    &lt;!&ndash;<i class="i-radio"></i>&ndash;&gt;-->
<!--                                                    <span class="i-radio" v-if="c_item.is_xz"></span>-->
<!--                                                </div>-->
<!--                                                <div class="choose">-->
<!--                                                    <span class="choose-item"></span>-->
<!--                                                    <span class="choose-answer">{{ c_item.text }}</span>-->
<!--                                                </div>-->
<!--                                            </label>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                            </template>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="Ctranslate unit-test-item">
                        <div class="item-title">
                            <span>【中译英（Tab跳转下一题，四个选项快捷键为1/2/3/4）】</span>
                        </div>
                        <div class="item-questions">
                            <template v-if="nianji_level == 0">
                                <ul :class="[
                                    'item-questions-info',
                                    { itemActive: tab_index02 == index },
                                ]" v-for="(item, index) in zyy_lists_daan" :key="index" @click="clkItem02(index)">
                                    <li class="info-title">
                                        <span class="t-num">{{ index + 1 }}.</span><span class="t-text t-chinese">{{
                                            item.analysis
                                        }}</span>
                                    </li>
                                    <li class="info-choose" v-for="(c_item, c_index) in item.shiyi_zyy" :key="c_index">
                                        <label>
                                            <div class="input-box">
                                                <input type="radio" :name="'yyz' + item.name" id="" @click="
                                                    xuanze_zyy(
                                                        index,
                                                        c_item,
                                                        c_index
                                                    )
                                                " />
                                                <!--<i class="i-radio"></i>-->
                                                <span class="i-radio" v-if="c_item.is_xz"></span>
                                            </div>
                                            <div :class="[
                                                'choose',
                                                c_item.is_xz
                                                    ? 'info-choose__check'
                                                    : '',
                                            ]">
                                                <span class="choose-item"></span>
                                                <span class="choose-answer c-english">{{ c_item.text }}</span>
                                            </div>
                                        </label>
                                    </li>
                                </ul>
                            </template>
                            <!-- 幼儿 -->
                            <template v-if="nianji_level == 1">
                                <ul class="item-questions-info baby-info" :class="[
                                    'item-questions-info',
                                    { itemActive: tab_index02 == index },
                                ]" v-for="(item, index) in zyy_lists_daan" :key="index" @click="clkItem02(index)">
                                    <li class="info-title baby-height">
                                        <div>
                                            <span class="t-num">{{ index + 1 }}.</span>
                                            <span class="t-text t-english">{{
                                                    item.analysis
                                                }}</span>
                                        </div>
                                        <img :src="audioUrl + item.img_link" alt="" @error="imgError">

                                    </li>
                                    <li class="info-choose" v-for="(c_item, c_index) in item.shiyi_zyy" :key="c_index">
                                        <!-- <label style="cursor: pointer">
                                        <img :class="[c_item.is_xz == 1  ? 'baby-checked' : '']" :src="audioUrl + c_item.img_link" alt="" @error="imgError">
                                        <div class="baby-item">
                                            <input
                                                    type="radio"
                                                    id=""
                                                    value=""
                                                    :name="'zyy' + item.name"
                                                    @click="
                                                        xuanze_zyy(
                                                            index,
                                                            c_item,
                                                            c_index
                                                        )
                                                    "
                                                    :ref="index + '_' + c_index"
                                            />
                                            <div class="choose">
                                                <span class="choose-item">
                                                    <span class="choose-item-text"></span>
                                                </span>
                                                <span class="choose-answer">{{c_item.text}}</span>
                                            </div>
                                        </div>
                                    </label> -->
                                        <!--                                    <img  :class="[c_item.is_xz == 1  ? 'baby-checked' : '']" :src="audioUrl + c_item.img_link" alt="" @error="imgError" @click="xuanze_zyy(index,c_item,c_index)">-->
                                        <label>
                                            <div class="input-box">
                                                <input type="radio" :name="'zyy' + item.name"
                                                       @click="xuanze_zyy(index, c_item, c_index)"
                                                       :ref="index + '_' + c_index" />
                                                <!--<i class="i-radio"></i>-->
                                                <span class="i-radio" v-if="c_item.is_xz"></span>
                                            </div>
                                            <div class="choose">
                                                <span class="choose-item"></span>
                                                <span class="choose-answer">{{ c_item.text }}</span>
                                            </div>
                                        </label>

                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                </template>

                <template v-if="kaoshi_status == 1">
<!--                    <div class="Etranslate unit-test-item">-->
<!--                        <div class="item-title">-->
<!--                            <span>【英译中（Tab跳转下一题，四个选项快捷键为1/2/3/4）】</span>-->
<!--                        </div>-->
<!--                        <div class="item-questions">-->
<!--                            <template v-if="nianji_level == 0">-->
<!--                                <ul class="item-questions-info" v-for="(item, index) in yyz_lists_daan" :key="index"-->
<!--                                    v-if="item.is_show == 1">-->
<!--                                    <li :class="[-->
<!--                                        'info-title',-->
<!--                                        item.is_right == 0 ? 'aError' : '',-->
<!--                                    ]">-->
<!--                                        <span class="t-num">{{ index + 1 }}.</span><span :class="[-->
<!--                                            't-text',-->
<!--                                            item.is_right == 0-->
<!--                                                ? 'title-answer'-->
<!--                                                : '',-->
<!--                                        ]">{{ item.name }}</span>-->
<!--                                    </li>-->
<!--                                    <li class="info-choose" v-for="(c_item, c_index) in item.shiyi_dx" :key="c_index">-->
<!--                                        <label>-->
<!--                                            <div class="input-box">-->
<!--                                                <input type="radio" :name="'a' + item.name" :checked="c_item.is_xz"-->
<!--                                                       disabled />-->
<!--                                                <i class="i-radio"></i>-->
<!--                                                <span class="i-radio" v-if="c_item.is_xz"></span>-->
<!--                                            </div>-->
<!--                                            <div :class="[-->
<!--                                                'choose',-->
<!--                                                {-->
<!--                                                    cCheck:-->
<!--                                                        c_item.is_xz == 0 &&-->
<!--                                                        c_item.is_true == 1,-->
<!--                                                },-->
<!--                                            ]">-->
<!--                                                <span class="choose-item"></span>-->
<!--                                                <span class="choose-answer">{{-->
<!--                                                        c_item.text-->
<!--                                                    }}</span>-->
<!--                                                <i class="el-icon-check" v-if="-->
<!--                                                    c_item.is_true == 1 &&-->
<!--                                                    c_item.is_xz == 1-->
<!--                                                "></i>-->
<!--                                                &lt;!&ndash;<i class="el-icon-close" v-if="c_item.is_xz == 1 && c_item.is_true == 0"></i>&ndash;&gt;-->
<!--                                            </div>-->
<!--                                        </label>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </template>-->
<!--                            &lt;!&ndash; 幼儿 &ndash;&gt;-->
<!--                            <template v-if="nianji_level == 1">-->
<!--                                <div class="item-questions-info" :class="[-->
<!--                                    'item-questions-info',-->
<!--                                    { itemActive: tab_index == index },-->
<!--                                ]" v-for="(item, index) in yyz_lists_daan" :key="index" v-if="item.is_show == 1">-->
<!--                                    <div class="info-title" :class="['info-title', item.is_right == 0 ? 'aError' : '',]">-->
<!--                                        <span class="t-num">{{ index + 1 }}.</span>-->
<!--                                        <span :class="[-->
<!--                                            't-text t-english',-->
<!--                                            item.is_right == 0-->
<!--                                                ? 'title-answer'-->
<!--                                                : '',-->
<!--                                        ]">{{-->
<!--                                                item.name-->
<!--                                            }}</span>-->
<!--                                    </div>-->
<!--                                    <ul class="info-select">-->
<!--                                        <li v-for="(c_item, c_index) in item.shiyi_dx" :key="c_index">-->
<!--                                            <img :class="[c_item.is_xz == 1 ? 'baby-checked' : '']"-->
<!--                                                 :src="audioUrl + c_item.img_link" alt="" @error="imgError">-->
<!--                                            <label>-->
<!--                                                <div class="input-box">-->
<!--                                                    <input type="radio" disabled :name="'zyy' + item.name"-->
<!--                                                           :ref="index + '_' + c_index" />-->
<!--                                                    &lt;!&ndash;<i class="i-radio"></i>&ndash;&gt;-->
<!--                                                    <span class="i-radio" v-if="c_item.is_xz"></span>-->
<!--                                                </div>-->
<!--                                                <div :class="[-->
<!--                                                    'choose',-->
<!--                                                    {-->
<!--                                                        cCheck:-->
<!--                                                            c_item.is_xz == 0 &&-->
<!--                                                            c_item.is_true == 1,-->
<!--                                                    },-->
<!--                                                ]">-->
<!--                                                    <span class="choose-item"></span>-->
<!--                                                    <span class="choose-answer">{{ c_item.text }}</span>-->
<!--                                                </div>-->
<!--                                            </label>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                            </template>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="Ctranslate unit-test-item">
                        <div class="item-title">
                            <span>【中译英（Tab跳转下一题，四个选项快捷键为1/2/3/4）】</span>
                        </div>
                        <div class="item-questions">
                            <template v-if="nianji_level == 0">
                                <ul class="item-questions-info" v-for="(item, index) in zyy_lists_daan" :key="index"
                                    v-if="item.is_show == 1">
                                    <li :class="[
                                        'info-title',
                                        item.is_right == 0 ? 'aError' : '',
                                    ]">
                                        <span class="t-num">{{ index + 1 }}.</span><span
                                            class="t-text title-answer__chinese">{{ item.analysis }}</span>
                                    </li>
                                    <li class="info-choose" v-for="(c_item, c_index) in item.shiyi_zyy" :key="c_index">
                                        <label>
                                            <div class="input-box">
                                                <input type="radio" :name="'b' + item.name" :checked="c_item.is_xz"
                                                       disabled />
                                                <i class="i-radio"></i>
                                                <span class="i-radio" v-if="c_item.is_xz"></span>
                                            </div>
                                            <div :class="[
                                                'choose',
                                                {
                                                    cCheck:
                                                        c_item.is_xz == 0 &&
                                                        c_item.is_true == 1,
                                                },
                                            ]">
                                                <span class="choose-item"></span>
                                                <span class="choose-answer c-english">{{ c_item.text }}</span>
                                                <i class="el-icon-check" v-if="
                                                    c_item.is_true == 1 &&
                                                    c_item.is_xz == 1
                                                "></i>
                                                <!--<i class="el-icon-close" v-if="c_item.is_xz == 1 && c_item.is_true == 0"></i>-->
                                            </div>
                                        </label>
                                    </li>
                                </ul>
                            </template>
                            <!-- 幼儿 -->
                            <template v-if="nianji_level == 1">
                                <ul class="item-questions-info baby-info" :class="[
                                    'item-questions-info',
                                    { itemActive: tab_index02 == index },
                                ]" v-for="(item, index) in zyy_lists_daan" :key="index" v-if="item.is_show == 1">
                                    <li class="info-title baby-height">
                                        <div>
                                            <span class="t-num">{{ index + 1 }}.</span>
                                            <span class="t-text t-english">{{
                                                    item.analysis
                                                }}</span>
                                        </div>
                                        <img :src="audioUrl + item.img_link" alt="" @error="imgError">
                                    </li>
                                    <li class="info-choose" v-for="(c_item, c_index) in item.shiyi_zyy" :key="c_index">
                                        <label>
                                            <div class="input-box">
                                                <input type="radio" :name="'b' + item.name" :checked="c_item.is_xz"
                                                       disabled />
                                                <i class="i-radio"></i>
                                                <span class="i-radio" v-if="c_item.is_xz"></span>
                                            </div>
                                            <div :class="[
                                                'choose',
                                                {
                                                    cCheck:
                                                        c_item.is_xz == 0 &&
                                                        c_item.is_true == 1,
                                                },
                                            ]">
                                                <span class="choose-item"></span>
                                                <span class="choose-answer c-english">{{ c_item.text }}</span>
                                                <i class="el-icon-check" v-if="
                                                    c_item.is_true == 1 &&
                                                    c_item.is_xz == 1
                                                "></i>
                                                <!--<i class="el-icon-close" v-if="c_item.is_xz == 1 && c_item.is_true == 0"></i>-->
                                            </div>
                                        </label>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                </template>

<!--                <div class="submit" v-if="(tab_show==1)">-->
<!--                    <el-button @click="nextTab"><span>下一环节-中译英</span><span></span></el-button>-->
<!--                </div>-->
                <div class="submit">
                    <el-button @click="closeDialog" v-if="kaoshi_status == 1"><span>关</span><span>闭</span></el-button>
                    <el-button @click="actionSave" v-if="kaoshi_status == 0"><span>交</span><span>卷</span></el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import $ from "jquery";
import { imgError, nianjiLevel } from "@/utils/utils";

export default {
    name: "reChinese",
    props: {
        testData: { type: Object },
        courseId: { type: Number, default: 0 },
        danyuanId: { type: Number, default: 0 },
        danyuanIdStr: { type: String, default: "" },
        nianjiId: { type: Number, default: 0 },
        courseName: { type: String },
        unitName: { type: String,default:'' },
        default: "",
        testTypeName: { type: String, default: "" },
        testType: { type: Number, default: 1 },
        audioUrl: { type: String, default: "" },
    },
    data() {
        return {
            avatarImg: '',
            userName: '',
            jsq_times: "00.00.00",
            cloakImg: require('@/assets/images/study/icons8-stopwatch.gif'),
            cloakSrc: require('@/assets/images/study/icons8-notification.gif'),
            dialogVisible: false,
            fullscreen: true,
            leter_serial: ["A", "B", "C", "D"],
            yyz_lists_daan: [],
            zyy_lists_daan: [],
            kaoshi_status: 0, //考试状态，0考试中，1考试结束
            scorse: 0,
            type: 5, //1记忆，2词听，3听写，4默写，5翻译（中译英）
            rightNum: 0, //正确多少道题
            isShowType: 0, //0是否显示错误,1显示全部
            audio_obj: [],
            maxtime: 0, //秒
            djs_msg: "",
            djs_count: 0,
            djs_obj: "",
            djs_state: 1, //倒计时状态,1运行，0删除
            djs_stop: 0, //1暂停，0进行中
            yongshi: 0,
            yongshi_time: "",
            action_status: 1, //防止提交多次
            tab_index: 0,
            tab_index02: -1,
            tab_show:1,//1中译英，2英译中
            chooseAudios: {
                jxnl: require("../../assets/audio/jxnl.mp3"),
                nzb: require("../../assets/audio/nzb.mp3"),
            },
            baoshi_num: 0,
            key_state: 0, //快捷键未按下
            d_code: "",
            yyz_ids: [],
            zyy_ids: [],
            nianji_level: 0,//年纪等级，0默认，1少儿
            imgError: null
        };
    },
    created() {
        this.getUserInfo()
        this.jsq()
        this.imgError = imgError;
        this.nianji_level = nianjiLevel(this.user, { nianji_id: this.nianjiId });
        console.log("nianji_level:", this.nianji_level);
        console.log('测试数据：', this.testData)
        //console.log("audioUrl:",this.audioUrl);
        this.initData();
        ////console.log("testType" + this.testType);
        //console.log("this.danyuanId:" + this.danyuanId);

        var myDate = new Date();
        var a = myDate.toLocaleString(); //获取日期与时间
        //console.log("当前时间："+a);
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        rightCount() {
            let right_count = 0;
            // this.yyz_lists_daan.forEach((item, index) => {
            //     if (item.is_right == 1) {
            //         right_count++;
            //     }
            // });
            this.zyy_lists_daan.forEach((item, index) => {
                if (item.is_right == 1) {
                    right_count++;
                }
            });
            return right_count;
        },
        errorCount() {
            let error_count = 0;
            // this.yyz_lists_daan.forEach((item, index) => {
            //     if (item.is_right == 0) {
            //         error_count++;
            //     }
            // });
            this.zyy_lists_daan.forEach((item, index) => {
                if (item.is_right == 0) {
                    error_count++;
                }
            });
            return error_count;
        },
        errorLists() {
            let errors = [];
            // this.yyz_lists_daan.forEach((item, index) => {
            //     if (item.is_right == 0) {
            //         errors.push(item.id);
            //     }
            // });
            this.zyy_lists_daan.forEach((item, index) => {
                if (item.is_right == 0) {
                    errors.push(item.id);
                }
            });
            return errors;
        },
        rightLists() {
            let right01 = [];
            let right02 = [];
            let right03 = [];
            // this.yyz_lists_daan.forEach((item, index) => {
            //     if (item.is_right == 1) {
            //         right01.push(item.id);
            //     }
            // });
            this.zyy_lists_daan.forEach((item, index) => {
                if (item.is_right == 1) {
                    right02.push(item.id);
                }
            });
            right03 = right01.filter((val) => {
                return right02.indexOf(val) > -1;
            });
            return right02;
        },
        timuNum() {
            return this.zyy_lists_daan.length;
        },
    },
    methods: {
        //封装一个处理单位数字的函数
        showNum(num) {
            if (num < 10) {
                return "0" + num;
            }
            return num;
        },
        nextTab(){
            let yyz_dis = this.$utils.getDisNums(this.yyz_ids, '英译中：');
            const confirmText = [];
            if (yyz_dis != "") {
                confirmText.push(yyz_dis);
            }
            //this.tab_show = 2;

            if(confirmText.length >0){
                this.$message({
                    message: confirmText,
                    type: 'warning',
                    offset:200
                });
            }else{
                this.tab_show = 2;
                this.tab_index = -1;
                this.tab_index02 = 0;
            }
        },
        jsq() {
            //点击开始建 开始计数
            let that = this;
            var uid = this.user.id;
            //console.log("uid:" + uid);
            var s = window.sessionStorage.getItem("jqx_count" + uid);
            var count = 0;
            if (s) {
                //console.log("sessionStorage:" + s);
                count = s;
            }
            var timer = null; //timer变量记录定时器setInterval的返回值
            timer = setInterval(function () {
                count++;
                window.sessionStorage.setItem("jqx_count" + uid, count);
                //console.log(count);
                // 需要改变页面上时分秒的值
                //console.log($("id_S"));
                var times_str = "";
                times_str += that.showNum(parseInt(count / 60 / 60)) + ":";
                times_str += that.showNum(parseInt(count / 60) % 60) + ":";
                times_str += that.showNum(count % 60);
                //console.log(times_str);
                that.jsq_times = times_str;
            }, 1000);
        },
        initData() {
            let test_data = this.testData;
            let that = this;
            this.zyy_lists_daan = test_data.zyy_lists.map(
                    (item, index, arr) => {
                        that.zyy_ids.push(index + 1);
                        for (var i = 0; i < item.shiyi_zyy.length; i++) {
                            item.shiyi_zyy[i].is_xz = 0;
                        }
                        let temp_item = {
                            id: item.id,
                            analysis: item.analysis,
                            name: item.name,
                            daan: "",
                            shiyi: item.shiyi,
                            shiyi_dx: item.shiyi_dx,
                            shiyi_zyy: item.shiyi_zyy,
                            is_right: 0,
                            is_show: 0,
                            timu_type: "zyy",
                            img_link: item.img_link
                        };
                        return temp_item;
                    }
            );
            //console.log("this.zyy_lists_daan");
            //console.log(this.zyy_lists_daan);

            this.yyz_lists_daan = test_data.yyz_lists.map(
                    (item, index, arr) => {
                        that.yyz_ids.push(index + 1);
                        for (var i = 0; i < item.shiyi_dx.length; i++) {
                            item.shiyi_dx[i].is_xz = 0;
                        }
                        let temp_item = {
                            id: item.id,
                            analysis: item.analysis,
                            name: item.name,
                            daan: "",
                            shiyi: item.shiyi,
                            shiyi_dx: item.shiyi_dx,
                            shiyi_zyy: item.shiyi_zyy,
                            is_right: 0,
                            is_show: 0,
                            timu_type: "yyz",
                            img_link: item.img_link
                        };
                        return temp_item;
                    }
            );

            //console.log("this.yyz_lists_daan");
            //console.log(this.yyz_lists_daan);
            if (this.nianji_level == 1) {
                this.maxtime = (this.zyy_lists_daan.length + this.yyz_lists_daan.length) * 6 * 2;//一，二年级时间多加一倍
            } else {
                this.maxtime = (this.zyy_lists_daan.length + this.yyz_lists_daan.length) * 6;
            }

            this.kaoshi_status = 0; //考试状态，0考试中，1考试结束
            this.scorse = 0;
            this.rightNum = 0; //正确多少道题
            this.isShowType = 0; //0是否显示错误,1显示全部
            this.djs_stop = 0;
            (this.action_status = 1), //防止提交多次
                    (this.tab_index = 0);
            this.tab_index02 = -1;
            this.setDjs();
        },
        getUserInfo() {
            let than = this;
            this.loading = true;
            //let danyuan_ids = this.danyuanCheckStr;
            let params = {
                token: this.token,
            };
            //console.log(params);
            this.axios
                    .post("/api/user/get_user_info", params)
                    .then((res) => {
                        let data = res.data;
                        //console.log("get_user_info");
                        console.log(data, "用户信息");
                        this.avatarImg = data.data.avatar
                        this.userName = data.data.truename
                        if (data.status_code == 200) {
                            //console.log(data.data);
                            this.$store.commit("setUser", data.data);
                        } else if (res.data.status_code == 202) {
                        } else {
                            //this.$message(res.data.msg)
                        }
                    })
                    .catch((err) => {
                        //this.loading = false
                    });
        },
        chooseAudio(type = 1) {
            let audio = new Audio();
            audio.controls = false;
            let src = this.chooseAudios.nzb;
            if (type === 0) {
                src = this.chooseAudios.jxnl;
            }
            audio.src = src;
            audio.volume = 0.5;
            audio.play();
        },
        xuanze_yyz(index, item, c_index) {
            //英译中
            //console.log('英译中'+index);
            //console.log('xuanze_yyz_refs');
            //console.log(this.$refs[index+'_'+c_index]);

            // let temp_item = this.yyz_lists_daan[index].shiyi_dx;
            // for (var i = 0; i < temp_item.length; i++) {
            //     temp_item[i].is_xz = 0;
            // }
            // temp_item[c_index].is_xz = 1;

            ////console.log(temp_item);
            if (item.is_true) {
                this.yyz_lists_daan[index].is_right = 1;
            } else {
                this.yyz_lists_daan[index].is_right = 0;
            }

            //console.log('xuanze_yyz_refs');
            //console.log(this.$refs[index+'_'+c_index]);

            this.$refs[index + "_" + c_index].checked = true;
            this.$utils.delArrByVal(this.yyz_ids, index + 1);
            /*let item01 = temp_item[c_index];
      //console.log(item01);
      this.$set(temp_item,c_index,item01)
      let item02 = this.yyz_lists_daan[index];
      this.$set(this.yyz_lists_daan,index,item02);
      ////console.log("this.yyz_lists_daan");
      ////console.log(this.yyz_lists_daan);
      this.$nextTick(() => {
          this.$forceUpdate();
      });*/

            ////console.log(this.yyz_lists_daan);
        },
        xuanze_zyy(index, item, c_index) {
            //中译英
            //console.log(index);
            let temp_item = this.zyy_lists_daan[index].shiyi_zyy;
            for (var i = 0; i < temp_item.length; i++) {
                temp_item[i].is_xz = 0;
            }
            temp_item[c_index].is_xz = 1;

            //console.log(temp_item);
            if (item.is_true) {
                this.zyy_lists_daan[index].is_right = 1;
            } else {
                this.zyy_lists_daan[index].is_right = 0;
            }
            this.$utils.delArrByVal(this.zyy_ids, index + 1);
            //console.log("this.zyy_lists_daan");
            //console.log(this.zyy_lists_daan);
        },
        againTest() {
            //this.initData();
            this.$emit("againTest");
        },
        actionSave() {
            this.djs_stop = 1;

            // let yyz_dis = this.$utils.getDisNums(this.yyz_ids, '英译中：');
            let zyy_dis = this.$utils.getDisNums(this.zyy_ids, '中译英：');
            //return;
            const confirmText = [];//'英译中：'+str_yyz+' 未做','中译英：'+str_zyy+' 未做'
            let titleText = '确定要交卷吗?';
            // if (yyz_dis != "") {
            //     confirmText.push(yyz_dis);
            // }
            if (zyy_dis != "") {
                confirmText.push(zyy_dis);
            }
            if (confirmText.length == 0) {
                titleText = '提示';
                confirmText.push('题目已答完，确定要交卷吗？');
            }


            const newDatas = []
            const h = this.$createElement
            for (const i in confirmText) {
                newDatas.push(h('p', null, confirmText[i]))
            }

            this.$confirm("确定要交卷吗?", "提示", {
                title: titleText,
                message: h('div', null, newDatas),
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                    .then(() => {
                        this.djs_state = 0;
                        this.clearDjs();
                        this.saveData();
                    })
                    .catch(() => {
                        this.djs_stop = 0;
                    });
        },
        saveData() {
            //console.log("errorLists",this.errorLists);
            /**
             yyz_lists_daan: [],
             zyy_lists_daan: [],
             djs_num: 0,
             kaoshi_status: 0, //考试状态，0考试中，1考试结束
             scorse: 0,
             type: 0,
             rightNum: 0, //正确多少道题
             isShowType: 0, //0是否显示错误,1显示全部
             */
            if (this.action_status == 0) return;
            this.action_status = 0;

            this.scorse = Math.round(Math.floor((this.rightCount / this.timuNum) * 100));
            this.showDaan(this.isShowType);
            let data = {
                user_id:this.user.id,
                token: this.token,
                scorse: this.scorse,
                test_type: this.testType,
                test_type_name: this.testTypeName,
                dadui_num: this.rightCount,
                course_id: this.courseId,
                danyuan_id: this.danyuanId,
                danyuan_id_str: this.danyuanIdStr,
                dacuo_num: this.errorCount,
                all_words_num: this.timuNum,
                yongshi: this.yongshi,
                yongshi_time: this.yongshi_time,
                type: this.type,
                data: [...this.zyy_lists_daan],
                error_lists: this.unique(this.errorLists),
                right_lists: this.unique(this.rightLists),
            };
            this.axios
                    .post("/api/test_center/save_test", data, {
                        loading: true,
                    })
                    .then((res) => {
                        //console.log(res);
                        if (res.data.status_code == 200) {
                            this.baoshi_num = res.data.data.integral;
                            this.kaoshi_status = 1;
                            //console.log("loading_close");
                            if (this.scorse > 80) {
                                this.chooseAudio(1);
                            } else {
                                this.chooseAudio(0);
                            }
                            //this.$message('提交成功');
                            /*this.$message({
                                message: "提交成功，测试完成",
                                offset: 300,
                                type: "success",
                                duration: "3000",
                            });*/
                            //this.emitClose(1, 1);
                        } else {
                            this.action_status = 1;
                            this.$message(res.data.msg);
                        }
                    })
                    .catch((err) => {
                        //this.loading = false;
                        this.hideLoading();
                        this.action_status = 1;
                        //console.log("loading_close");
                    });
            /*setTimeout(() => {
          if(this.action_status == 0){
              //this.loading = false;
          }
        }, 3000);*/
            //this.$emit('changeTitle');
        },
        closeDialog() {
            if (this.kaoshi_status == 0) {
                this.djs_stop = 1;
                this.$confirm("您还没有交卷，确定要返回吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                        .then(() => {
                            this.kaoshi_status = 1;
                            this.djs_state = 0;
                            this.clearDjs();
                            this.$emit("close");
                        })
                        .catch(() => {
                            this.djs_stop = 0;
                        });
            } else {
                this.djs_state = 0;
                this.clearDjs();
                this.$emit("close");
            }
        },
        showDaan(index) {
            //this.kaoshi_status = 2;
            this.isShowType = index;
            for (var i = 0; i < this.yyz_lists_daan.length; i++) {
                if (this.isShowType == 0) {
                    if (this.yyz_lists_daan[i].is_right != 1) {
                        this.yyz_lists_daan[i].is_show = 1;
                    } else {
                        this.yyz_lists_daan[i].is_show = 0;
                    }
                } else if (this.isShowType == 1) {
                    this.yyz_lists_daan[i].is_show = 1;
                }
            }

            for (var i = 0; i < this.zyy_lists_daan.length; i++) {
                if (this.isShowType == 0) {
                    if (this.zyy_lists_daan[i].is_right != 1) {
                        this.zyy_lists_daan[i].is_show = 1;
                    } else {
                        this.zyy_lists_daan[i].is_show = 0;
                    }
                } else if (this.isShowType == 1) {
                    this.zyy_lists_daan[i].is_show = 1;
                }
            }
        },
        cuociGonggu() {
            if (this.scorse == 100) {
                this.$message({
                    message: "没有错词",
                    type: "warning",
                });
                return;
            }
            let err_lists = this.unique(this.errorLists);
            this.$emit("cuociGonggu", err_lists);
            ////console.log(this.errorLists);
        },
        /**
         * [study_djs 倒计时]
         * @param  {Number} value [description]
         * @return {[type]}       [description]
         */
        setDjs(value = 0) {
            var that = this;
            that.djs_count = that.maxtime;
            if (this.djs_obj) {
                clearInterval(this.djs_obj);
            }
            if (value > 0) {
                that.djs_count = value;
            }

            let maxtime = that.djs_count;
            this.djs_obj = setInterval(function () {
                if (that.djs_state != 1) {
                    clearInterval(that.djs_obj);
                    that.djs_msg = "";
                    return false;
                }
                ////console.log("maxtime" + maxtime);
                if (that.djs_stop == 1) {
                    return;
                }
                if (maxtime >= 0) {
                    let hour = 0;
                    let minutes = Math.floor(maxtime / 60);
                    if (minutes > 60) {
                        hour = Math.floor(minutes / 60);
                        minutes = Math.floor(minutes % 60);
                    }
                    let seconds = Math.floor(maxtime % 60);
                    if (hour < 10) {
                        hour = "0" + hour;
                    }
                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    }
                    if (seconds < 10) {
                        seconds = "0" + seconds;
                    }
                    that.djs_msg = hour + ":" + minutes + ":" + seconds;
                    if (that.kaoshi_status == 0) {
                        --maxtime;
                        that.zj_yongshi();
                    }
                } else {
                    clearInterval(that.djs_obj);
                    that.djs_msg = "";
                    that.kaoshi_status = 1;
                    that.saveData();
                }
            }, 1000);
        },
        clearDjs() {
            if (this.djs_obj) {
                clearInterval(this.djs_obj);
            }
        },
        zj_yongshi() {
            var that = this;
            that.yongshi++;
            var hour = Math.floor(that.yongshi / 3600);
            var minute = Math.floor((that.yongshi - hour * 3600) / 60);
            var second = that.yongshi - hour * 3600 - minute * 60;
            if (hour > 0) {
                that.yongshi_time = hour + "时" + minute + "分" + second + "秒";
            } else {
                that.yongshi_time = minute + "分" + second + "秒";
            }

            //console.log(that.yongshi_time);
        },
        unique(arr) {
            const seen = new Map();
            return arr.filter((a) => !seen.has(a) && seen.set(a, 1));
        },
        clkItem(index) {
            this.tab_index = index;
            this.tab_index02 = -1;
        },
        clkItem02(index) {
            this.tab_index02 = index;
            this.tab_index = -1;
        }, //监听键盘事件
        key_down(event) {
            if (this.key_state != 1) {
                this.key_state = 1;
                this.d_code = event.keyCode;
            }
        },
        key_up(event) {
            if (this.action_status == 0) {
                return;
            }
            ////console.log("code:", code);
            if (event.keyCode == this.d_code) {
                this.key_state = 0;
                this.d_code = "";
            }

            var code = event.keyCode;
            if (this.d_code == 16) {
                code = 1000 + code;
            } else if (this.d_code && this.d_code != 18) {
                //禁止其他组合键(不包括ctrl）
                return false;
            }
            if (code == 9) {
                var num = this.timuNum / 2;
                if (this.tab_index + 1 >= num || this.tab_index == -1) {
                    this.tab_index = -1;
                    if (this.tab_index02 + 1 >= num) {
                        this.tab_index02 = -1;
                    } else {
                        this.tab_index02++; //中译英
                    }
                } else {
                    //英译中
                    this.tab_index++;
                }
            }

            var xuanxiang = [49, 50, 51, 52, 97, 98, 99, 100]; //1、2、3、4
            let is_at = 0; //判断是否1，2，3，4
            xuanxiang.forEach((item) => {
                if (item === code) {
                    is_at = 1;
                }
            });

            let i = 0;
            if (is_at) {
                switch (code) {
                    case 49:
                        i = 0;
                        break;
                    case 50:
                        i = 1;
                        break;
                    case 51:
                        i = 2;
                        break;
                    case 52:
                        i = 3;
                        break;
                    case 97:
                        i = 0;
                        break;
                    case 98:
                        i = 1;
                        break;
                    case 99:
                        i = 2;
                        break;
                    case 100:
                        i = 3;
                        break;
                    default:
                        i = 0;
                        break;
                }
                if (this.tab_index >= 0) {
                    var xz_words = this.yyz_lists_daan[this.tab_index];
                    var shiyi = xz_words.shiyi_dx;
                    shiyi.forEach((item, index) => {
                        if (index == i) {
                            //console.log('选项：'+i);
                            this.xuanze_yyz(this.tab_index, item, index);
                        }
                    });
                }

                if (this.tab_index02 >= 0) {
                    var xz_words = this.zyy_lists_daan[this.tab_index02];
                    var shiyi = xz_words.shiyi_zyy;
                    shiyi.forEach((item, index) => {
                        if (index == i) {
                            //console.log('选项：'+i);
                            this.xuanze_zyy(this.tab_index02, item, index);
                        }
                    });
                }
            }
        },
    },
    mounted: function () {
        let that = this;
        //键盘事件
        /*var key_state = 0; //未按下
    var d_code = "";
    $(document).keydown(function (event) {
      if (key_state != 1) {
        key_state = 1;
        d_code = event.keyCode;
      }
    });
    $(document).keyup(function (event) {
      if (event.keyCode == d_code) {
        key_state = 0;
        d_code = "";
      }
      //console.log("event");
      //console.log(event);
      that.key_event(event.keyCode, d_code);
    });*/

        window.addEventListener("keyup", this.key_up, false);
        window.addEventListener("keydown", this.key_down, false);
    },
    destroyed() {
        window.removeEventListener("keyup", this.key_up);
        window.removeEventListener("keydown", this.key_down);
        this.clearDjs();
    },
};
</script>

<style lang="scss">
@import "@/assets/css/ai_unit_test.scss";
.achievement-questions{
    position: absolute;
    bottom: 40px;
    width: 100%;
}

.achievement-questions .right-check {
    color: #2bc2a1;
    padding-right: 15px;
}

.achievement-questions .right-error {
    color: #FF3F3F;
    padding-right: 15px;
}
.topbar-container__sidebar {
    display: flex;
    align-items: center;
}
.dialog-title__persons {
    padding: 2px 8px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    img {
        margin-right: 5px;
        width: 20px;
        height: 20px;
        vertical-align: -4px;
    }
}
.left-timer {
    position: fixed;
    top: 60px;
    left: 60px;
}
.online-time {
    &__title {
        span {
            font-size: 16px;
            font-weight: 400;
        }
    }
    .left-time {
        padding: 2px 10px;
        font-size: 30px;
        color: #ff6a00;
        font-weight: bold;
        background-color: #EDFEF9;
    }
    .right-time {
        padding: 2px 10px;
        background-color: #FDF4EF;
    }
    img {
        width: 40px;
        height: 40px;
    }
    &.timer {
        position: fixed;
        top: 60px;
        right: 60px;
    }
}
</style>
