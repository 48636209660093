<template>
    <div>
        <div class="center-box">
            <div class="center">
                <div class="center-title">
                    <div class="title-l">
                        <span class="short">单词速记</span>
                        >
                        <span class="cTest">测试中心</span>
                    </div>
                    <div class="title-r">
                        <el-button icon=" el-icon-refresh-left" type="success" circle plain style="margin-right: 10px;" @click="refresh" />
                        <span class="record" @click="gotoTest">测试记录</span>
                        <span class="return" @click="backStudy">返回学习中心</span>
                    </div>
                </div>
                <div class="center-container">
                    <div class="center-l">
                        <ul class="center-list">
                            <li
                                    class=""
                                    :class="['c-item', index == left_index ? 'itemHover' : '']"
                                    v-for="(item, index) in left_nav"
                                    :key="index"
                                    @click="clkLeftNav(index, item.id)"
                                    @mouseover="removeAnimate('.rc-table')"
                            >
                                {{ item.name }}
                            </li>
                        </ul>
                    </div>
                    <div class="center-r">
                        <div class="r-box">
                            <div class="box-title">
                <span class="b-text">
                  当前课程：
                  <span>{{ course_name }}</span>
                  <span></span>
                </span>
                            </div>
                            <template v-if="left_nav_id != 0">
                                <div class="r-btn">
                                    <div
                                            class="b-info"
                                            :class="['b-info', test_index == index ? 'infoCheck' : '']"
                                            v-for="(item, index) in test_nav"
                                            :key="index"
                                            @click="clkTestNav(item, index)"
                                    >
                                        <span class="today">{{ item.name }}</span>
                                        <span class="tNum num" v-if="index == 2">{{
                                                danyuanCheckCount
                                            }}</span>
                                        <span class="tNum num" v-if="index != 2">{{
                                                item.num
                                            }}</span>
                                    </div>
                                    <!--<div class="b-info">
                                                      <span class="learned">已学测试</span>
                                                      <span class="lNum num">0</span>
                                                  </div>
                                                  <div class="b-info">
                                                      <span class="unitTest">单元测试</span>
                                                      <span class="uNum num">0</span>
                                                  </div>
                                                  <div class="b-info infoCheck">
                                                      <span class="whole">全词测试</span>
                                                      <span class="wNum num">0</span>
                                                  </div>-->
                                </div>
                                <div class="record" v-show="test_log_show == 1">
                                    <div class="rc-container">
                                        <div class="rc-title">
                                            <div class="rc-test">
                                                <span class="rc-whole">全词测试</span>-
                                                <span class="rc-text">测试记录</span>
                                            </div>
                                            <div class="trend" @click="clkZhoushi">
                                                <span class="trend-text">走势图</span>
                                                <i class="el-icon-s-marketing"></i>
                                            </div>
                                        </div>
                                        <div class="chart-container" v-show="zoushi_show == 1">
                                            <div class="chart-title">
                                                <span>全词测试</span>—
                                                <span>近十次分数走势图</span>
                                            </div>
                                            <div
                                                    id="main"
                                                    style="width: 100%; height: 300px"
                                                    ref="chart"
                                            ></div>
                                        </div>
                                        <div class="rc-table">
                                            <el-table
                                                    :data="test_log_lists"
                                                    border
                                                    style="width: 100%"
                                            >
                                                <el-table-column
                                                        prop="inter"
                                                        label="成绩"
                                                        width="168"
                                                ></el-table-column>
                                                <el-table-column
                                                        prop="yongshi_time"
                                                        label="测试时长"
                                                        width="168"
                                                ></el-table-column>
                                                <el-table-column
                                                        prop="created_at"
                                                        label="日期"
                                                        width="168"
                                                ></el-table-column>
                                                <el-table-column
                                                        prop="assess"
                                                        label="评价"
                                                        width="169"
                                                        class="pass"
                                                >
                                                    <template slot-scope="scope">
                                                        <i
                                                                class="el-icon-top"
                                                                style="color: #2bc2a1"
                                                                v-if="scope.row.diff_inter > 0"
                                                        ></i>
                                                        <i class="" v-if="scope.row.diff_inter == 0">-</i>
                                                        <i
                                                                class="el-icon-bottom"
                                                                style="color: #ff4040"
                                                                v-if="scope.row.diff_inter < 0"
                                                        ></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="handleCurrentChange"
                                                :total="test_log_count"
                                                :hide-on-single-page="true"
                                            >
                                            </el-pagination>
                                        </div>
                                    </div>
                                </div>
                                <div class="record" v-if="danyuan_mod_show == 1">
                                    <div class="m-container">
                                        <div>
                                            <ul class="m-container-list">
                                                <li
                                                        :class="['m-list__menu', danyuan_id_check[item.id] ? 'selected' : '']"
                                                        v-for="(item, index) in danyuan_arr"
                                                        :key="index"
                                                        @click="clkDanyuan(item, index)"
                                                >
                                                    <div class="m-list__menu--item">
                                                        <img
                                                                src="@/assets/images/login/words.png"
                                                                alt="logo"
                                                                class="menu-logo"
                                                        />
                                                        <span class="menu-unit">{{ item.name }}</span>
                                                        <span
                                                                class="menu-test"
                                                                v-if="danyuan_id_check[item.id]"
                                                        >测</span
                                                        >
                                                    </div>

                                                    <template v-for="(b_item, b_index) in item.jindu">
                                                        <span v-if="b_item.is_tast > 0 && b_index === (type - 1)" class="test-txt">任务</span>
                                                        <span :key="b_index" v-if="b_item.pass_num > 0 && b_index === (type - 1)" class="test-count">{{ b_item.pass_num }}</span>
                                                    </template>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="m-list__menu--btn">
                                            <el-button class="btn-reset" @click="resetCheck"
                                            >重置
                                            </el-button
                                            >
                                            <el-button class="btn-confirm" @click="doCeshi"
                                            >确定
                                            </el-button
                                            >
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <el-dialog
                                    class="unitTest"
                                    fullscreen
                                    :visible.sync="reTestVisible"
                                    v-if="reTestVisible"
                                    width="914px"
                                    :title="testTitle"
                                    :close-on-press-escape="false"
                            >
                                <reTest
                                        @close="closeDialog"
                                        @cuociGonggu="cuociGonggu"
                                        @againTest="againTest"
                                        ref="dialog_jiyi"
                                        :course-id="course_id"
                                        :danyuan-id-str="danyuanCheckStr"
                                        :test-data="test_data"
                                        :course-name="course_name"
                                        :unit-name="unitName"
                                        :test-type="test_type"
                                        :test-type-name="test_type_name[test_type - 1]"
                                        :nianji-id="nianji_id"
                                        :audio-url="audioUrl"
                                />
                            </el-dialog>
                            <el-dialog
                                    class="unitTest"
                                    fullscreen
                                    :visible.sync="chineseVisible"
                                    v-if="chineseVisible"
                                    width="914px"
                                    :title="testTitle"
                                    :close-on-press-escape="false"
                            >
                                <re-chinese
                                        @close="closeDialog"
                                        @cuociGonggu="cuociGonggu"
                                        @againTest="againTest"
                                        ref="dialog_jiyi"
                                        :course-id="course_id"
                                        :danyuan-id-str="danyuanCheckStr"
                                        :test-data="test_data"
                                        :course-name="course_name"
                                        :unit-name="unitName"
                                        :test-type="test_type"
                                        :test-type-name="test_type_name[test_type - 1]"
                                        :nianji-id="nianji_id"
                                        :audio-url="audioUrl"
                                />
                            </el-dialog>
                            <el-dialog
                                    class="unitTest"
                                    fullscreen
                                    :visible.sync="liTestVisible"
                                    v-if="liTestVisible"
                                    width="914px"
                                    :title="testTitle"
                                    :close-on-press-escape="false"
                            >
                                <liTestPindu  @close="closeDialog"
                                        @cuociGonggu="cuociGonggu"
                                        @againTest="againTest"
                                        ref="dialog_citing"
                                        :audio-url="audioUrl"
                                        :course-id="course_id"
                                        :danyuan-id-str="danyuanCheckStr"
                                        :test-data="test_data"
                                        :course-name="course_name"
                                        :unit-name="unitName"
                                        :test-type="test_type"
                                        :test-type-name="test_type_name[test_type - 1]"
                                        :nianji-id="nianji_id"
                                        v-if="banben_id==7"/>
                                <liTest
                                        @close="closeDialog"
                                        @cuociGonggu="cuociGonggu"
                                        @againTest="againTest"
                                        ref="dialog_citing"
                                        :audio-url="audioUrl"
                                        :course-id="course_id"
                                        :danyuan-id-str="danyuanCheckStr"
                                        :test-data="test_data"
                                        :course-name="course_name"
                                        :unit-name="unitName"
                                        :test-type="test_type"
                                        :test-type-name="test_type_name[test_type - 1]"
                                        :nianji-id="nianji_id"
                                        v-else
                                />
                            </el-dialog>
                            <el-dialog
                                    class="unitTest"
                                    fullscreen
                                    :visible.sync="wrTestVisible"
                                    v-if="wrTestVisible"
                                    width="914px"
                                    :title="testTitle"
                                    :close-on-press-escape="false"
                            >
                                <wrTest
                                        @close="closeDialog"
                                        @cuociGonggu="cuociGonggu"
                                        @againTest="againTest"
                                        ref="dialog_tingxie"
                                        :audio-url="audioUrl"
                                        :course-id="course_id"
                                        :danyuan-id-str="danyuanCheckStr"
                                        :test-data="test_data"
                                        :course-name="course_name"
                                        :unit-name="unitName"
                                        :test-type="test_type"
                                        :test-type-name="test_type_name[test_type - 1]"
                                        :nianji-id="nianji_id"
                                        :test-times="testTimes"
                                />
                            </el-dialog>
                            <el-dialog
                                    class="unitTest"
                                    fullscreen
                                    :visible.sync="diTestVisible"
                                    v-if="diTestVisible"
                                    width="914px"
                                    :title="testTitle"
                                    :close-on-press-escape="false"
                            >
                                <diTest
                                        @close="closeDialog"
                                        @cuociGonggu="cuociGonggu"
                                        @againTest="againTest"
                                        ref="dialog_moxie"
                                        :audio-url="audioUrl"
                                        :course-id="course_id"
                                        :danyuan-id-str="danyuanCheckStr"
                                        :test-data="test_data"
                                        :course-name="course_name"
                                        :unit-name="unitName"
                                        :test-type="test_type"
                                        :test-type-name="test_type_name[test_type - 1]"
                                        :nianji-id="nianji_id"
                                />
                            </el-dialog>
                            <el-dialog
                                    title="智能翻译"
                                    width="1000px"
                                    v-if="dialogVisible"
                                    :visible.sync="dialogVisible"
                                    :close-on-click-modal="close"
                                    :show-close="close"
                                    :close-on-press-escape="false"
                            >
                                <aiRemeber
                                        @close="handleClose"
                                        title="hello!"
                                        :audio-url="audioUrl"
                                        :all-lists="cuociLists"
                                        :words-lists="cuociLists"
                                        :study-type="study_type"
                                        ref="dialog_jiyi"
                                />
                            </el-dialog>
                            <el-dialog
                                    title="智能听选"
                                    width="1000px"
                                    v-if="ciVisible"
                                    :visible.sync="ciVisible"
                                    :close-on-click-modal="close"
                                    :show-close="close"
                                    :close-on-press-escape="false"
                            >
                                <aiBaby @close="handleClose"
                                        :audio-url="audioUrl"
                                        :all-lists="cuociLists"
                                        :words-lists="cuociLists"
                                        :study-type="study_type"
                                        ref="dialog_citing"
                                        v-if="banben_id==7"/>
                                <aiListen
                                        @close="handleClose"
                                        :audio-url="audioUrl"
                                        :all-lists="cuociLists"
                                        :words-lists="cuociLists"
                                        :study-type="study_type"
                                        ref="dialog_citing"
                                        v-else
                                />
                            </el-dialog>
                            <el-dialog
                                    title="智能听写"
                                    width="1000px"
                                    v-if="writeVisible"
                                    :visible.sync="writeVisible"
                                    :close-on-click-modal="close"
                                    :show-close="close"
                                    :close-on-press-escape="false"
                            >
                                <aiWrite
                                        @close="handleClose"
                                        :audio-url="audioUrl"
                                        :all-lists="cuociLists"
                                        :words-lists="cuociLists"
                                        :study-type="study_type"
                                        ref="dialog_tingxie"
                                />
                            </el-dialog>
                            <el-dialog
                                    title="智能默写"
                                    width="1000px"
                                    v-if="diVisible"
                                    :visible.sync="diVisible"
                                    :close-on-click-modal="close"
                                    :show-close="close"
                                    :close-on-press-escape="false"
                            >
                                <aiDictation
                                        @close="handleClose"
                                        :audio-url="audioUrl"
                                        :all-lists="cuociLists"
                                        :words-lists="cuociLists"
                                        :study-type="study_type"
                                        ref="dialog_moxie"
                                />
                            </el-dialog>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

import aiRemeber from "@/components/ai_word_components/ai_remeber"; // 记忆模块
import aiListen from "@/components/ai_word_components/ai_listen"; // 词听模块// 幼儿听力
import aiBaby from "@/components/ai_word_components/ai_listen_pindu"
import aiWrite from "@/components/ai_word_components/ai_write"; // 听写模块
import aiDictation from "@/components/ai_word_components/ai_dictation"; // 默写模块

import reTest from "@/components/ai_word_components/re_unit_test"; //记忆测试
import reChinese from "@/components/ai_word_components/re_unit_test_chinese"; // 中译英
import liTest from "@/components/ai_word_components/li_unit_test"; //词听测试
import liTestPindu from "@/components/ai_word_components/li_unit_test_pindu"; //词听测试
import wrTest from "@/components/ai_word_components/wr_unit_test"; //听写测试
import diTest from "@/components/ai_word_components/di_unit_test"; //默写测试
let echarts = require("echarts");
export default {
    inject: ['reload'],
    components: {
        aiRemeber,
        aiListen,
        aiWrite,
        aiDictation,
        reTest,
        wrTest,
        liTest,
        diTest,
        aiBaby,
        liTestPindu,
        reChinese
    },
    data() {
        return {
            dialogVisible: false, // 记忆模块
            chineseVisible: false, // 智能翻译（中译英）
            ciVisible: false, // 词听模块
            writeVisible: false, // 听写模块
            diVisible: false, // 默写模块
            reTestVisible: false,
            wrTestVisible: false,
            liTestVisible: false,
            diTestVisible: false,
            audioUrl: "", //音频域名地址
            testTitle:
                    "单元检测-[外研小学2013五年级下（三年级起点）-unit3]-(测试15道题)",
            tableData: [
                {
                    date: "2021-09-07 16: 42",
                    time: "56秒",
                    achievement: "96",
                    assess: "进步",
                },
                {
                    date: "2021-09-07 16: 42",
                    time: "15秒",
                    achievement: "50",
                    assess: "",
                },
            ],
            left_nav: [
                {id: 1, name: "智能翻译(英译中)"},
                {id: 5, name: "智能翻译(中译英)"},
                {id: 2, name: "智能听选"},
                {id: 3, name: "智能听写"},
                {id: 4, name: "智能默写"},
            ], //"例句听组","例句听力","例句翻译","例句默写",{'id':0,'name':'水平测试'},
            left_nav_id: 1,
            left_index: 0,
            test_nav: [
                {name: "今日已学测试", num: 0},
                {name: "已学测试", num: 0},
                {name: "查漏补缺", num: 0},
                {name: "全词测试", num: 0},
            ],
            test_nav_data: [], //已学数据
            test_index: 0,
            course_id: 0,
            course_name: "",
            banben_id:0,
            //course: {},
            //danyuan: {},
            type: 1, //1记忆，2词听，3听写，4默写
            danyuan_arr: [],
            danyuan_id_arr: [], //选择要测试的单词
            danyuan_id_check: [], //选中的单元
            test_log: [],
            danyuan_mod_show: 0, //单元模块是否显示
            zoushi_show: 0, //走势图模块是否显示
            test_log_show: 1, //测试记录是否显示
            test_type: 1, //测试类型
            test_type_name: [
                "今日已学测试",
                "已学测试",
                "查漏补缺",
                "全词测试",
                "错词测试",
            ],
            test_data: {},
            test_log_lists: [],
            test_log_count: 0,
            myChart: "",
            study_type: 3, //错词巩固
            cuociLists: [],
            close: false,
            nianji_id:0
        };
    },
    created() {
        this.audioUrl = this.$conf.audio_url;
        this.initData();
        this.getIndex();
        this.getTestLog();
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        danyuanCheckStr() {
            let str = "";
            this.danyuan_id_check.forEach((item, index) => {
                if (item) {
                    str += index + ",";
                }
            });
            if (str.length > 0) {
                str = str.slice(0, str.length - 1);
            }
            //console.log("danyuanstr");
            //console.log(str);
            return str;
        },
        unitName(){
            //选择的单元名称
            let unit = [];
            let unitName = "";
            this.danyuan_id_check.forEach((item, index) => {
                if (item) {
                    unit.push(index);
                }
            });
            if(unit.length == 1){
                this.danyuan_arr.forEach((item,index)=>{
                    if(item.id == unit[0]){
                        unitName = item.name;
                    }
                });
            }else{
                unitName = "";
            }
            return unitName;
        },
        danyuanCheckCount() {
            let count = 0;
            this.danyuan_id_check.forEach((item, index) => {
                if (item) {
                    count++;
                }
            });
            return count;
        },
        testTimes(){
            let times = 0;
            /*let str = "";
            this.danyuan_id_check.forEach((item, index) => {
                if (item) {
                    str += index + ",";
                }
            });
            if (str.length > 0) {
                str = str.slice(0, str.length - 1);
            }

            let checkIds =  this.str.split(",")

            this.danyuan_arr.forEach((item,index)=>{
                if(checkIds.indexOf(item.id)!=-1){
                    times += item.dictation_minute;
                }
            })
            console.log("testTimes",testTimes);*/
            return times;
        }
    },
    methods: {
        refresh() {
            this.reload()
        },
        fadeIns(arg) {
            let dContent = document.querySelector(arg);
            dContent.classList.add("fadeIns");
        },
        removeAnimate(arg) {
            let dContent = document.querySelector(arg);
            dContent.classList.remove("fadeIns");
            //console.log(dContent.classList);
        },
        initData() {
            this.course_id = Number(this.$route.query.course_id);
            this.course_name = this.$route.query.course_name;
            this.banben_id = this.$route.query.banben_id;
            this.getDanyuan();
            //window.localStorage.getItem('currentDanyuan');
            //window.localStorage.setItem('currentDanyuan',JSON.stringify(this.currentDanyuan));
            //console.log(this.course_id);
        },
        clkLeftNav(index, id) {
            this.fadeIns(".rc-table");
            this.left_index = index;
            this.left_nav_id = id;
            //console.log(this.danyuan_arr);

            this.setDanyuanData(this.left_nav_id);
            this.danyuan_id_check = [];
            this.test_index = 0;
            this.test_log_show = 1;
            this.danyuan_mod_show = 0;
            this.type = this.left_nav_id;
            // if (this.left_nav_id === 5) {
            //     this.left_nav_id = 4
            // }
            this.getTestLog();
            this.setTestNavData(this.left_nav_id);

            //console.log(this.test_nav);
        },
        clkTestNav(item, index) {
            this.test_index = index;
            this.test_type = index + 1;
            if (index == 2) {
                console.log('查漏补缺：', item)
                this.danyuan_mod_show = 1;
                this.test_log_show = 0;
            } else {
                this.danyuan_id_check = [];
                if (item.num > 0) {
                    this.danyuan_mod_show = 0;
                    this.test_log_show = 1;
                    this.getWords();
                } else {
                    this.$message({
                        message: "没有需要测试的单词",
                        offset: 300,
                        type: "warning",
                        duration: "1000",
                    });
                }
            }
            //this.testVisible = true;
        },
        clkZhoushi() {
            this.zoushi_show = !this.zoushi_show;
            //this.setEacharts();
            //this.myChart.resize();
        },
        clkDanyuan(item, index) {
            //this.danyuan_id_check[item.id] = !this.danyuan_id_check[item.id];
            let ckItem = this.danyuan_id_check[item.id];
            ckItem = !ckItem;
            //console.log(this.danyuan_id_check);
            this.$set(this.danyuan_id_check, item.id, ckItem);
        },
        doCeshi() {
            //this.testVisible = true
            if (this.danyuanCheckStr == "") {
                this.$message({
                    message: "请选择需要测试的单元",
                    offset: 300,
                    type: "warning",
                    duration: "1000",
                });
                return;
            }
            this.getWords();
            //console.log(this.danyuanCheckStr);
        },
        resetCheck() {
            this.danyuan_id_check = [];
        },
        setDanyuanData(index) {
            var j = index - 1;
            // if (index === 5) {
            //     j = 0
            // }
            console.log(j)
            this.danyuan_arr = this.danyuan_arr.map((item, i, arr) => {
                item.is_xuewan = item.jindu[j].is_xuewan;

                /*if (index == 0) {
                  item.is_xuewan = item.b_is_xuewan;
                } else if (index == 1) {
                  item.is_xuewan = item.c_is_xuewan;
                } else if (index == 2) {
                  item.is_xuewan = item.d_is_xuewan;
                } else if (index == 3) {
                  item.is_xuewan = item.e_is_xuewan;
                }*/
                return item;
            });
        },
        getIndex() {
            this.loading = true;
            this.axios
                    .post("/api/test_center/index", {
                        token: this.token,
                        type: this.type,
                        course_id: this.course_id,
                    })
                    .then((res) => {
                        //console.log(res);
                        var data = res.data;
                        if (data.status_code == 200) {
                            this.test_nav_data = data.data;
                            //console.log("test_nav");
                            this.setTestNavData(this.type);
                            //console.log(this.test_nav);
                        } else if (res.data.status_code == 202) {
                        } else {
                            //this.$message(res.data.msg)
                        }
                    })
                    .catch((err) => {
                        //this.loading = false
                    });
        },
        setTestNavData(index) {
            let i = index - 1;
            if (index === 5) {
                i = 0
            }
            this.$set(this.test_nav, 0, {
                name: "今日已学测试",
                num: this.test_nav_data[i].day_num,
            });
            this.$set(this.test_nav, 1, {
                name: "已学测试",
                num: this.test_nav_data[i].xuexi_num,
            });
            this.$set(this.test_nav, 3, {
                name: "全词测试",
                num: this.test_nav_data[i].all_num,
            });
        },
        getDanyuan() {
            this.loading = true;
            this.axios
                    .post("/api/test_center/get_danyuan", {
                        token: this.token,
                        type: this.type,
                        course_id: this.course_id,
                    })
                    .then((res) => {
                        //console.log(res);
                        var data = res.data;
                        if (data.status_code == 200) {
                            this.danyuan_arr = data.data.lists;
                            this.danyuan_arr.forEach((item) => {
                                this.danyuan_id_check[item.id] = false;
                            });
                            //console.log("setDanyuanData");
                            this.setDanyuanData(this.left_nav_id);
                            //console.log(this.danyuan_id_check);
                        } else if (res.data.status_code == 202) {
                        } else {
                            //this.$message(res.data.msg)
                        }
                    })
                    .catch((err) => {
                        //this.loading = false
                    });
        },
        getWords() {
            let than = this;
            this.loading = true;
            let danyuan_ids = this.danyuanCheckStr;
            let params = {
                token: this.token,
                type: this.type,
                course_id: this.course_id,
                test_type: this.test_type,
                danyuan_ids: danyuan_ids,
            };
            if (this.type === 5) {
                params.type = 1
            }
            console.log('传递的参数：', params);
            this.axios.post("/api/test_center/get_word", params,{
					loading:true
				}).then((res) => {
                //console.log(res);
                var data = res.data;

                if (data.status_code == 200) {
                    this.test_data = data.data;
                    console.log('测试数据1：', this.test_data)
                    this.nianji_id = parseInt(data.data.nianji_id);
                    //console.log("test_data：nianji_id："+this.nianji_id);
                    //console.log(this.test_data);
                    than.showDialog();
                } else if (res.data.status_code == 202) {
                } else {
                    //this.$message(res.data.msg)
                }
            })
            .catch((err) => {
                //this.loading = false
            });
        },
        getWordsCuociGonggu(words_ids) {
            let than = this;
            this.loading = true;
            let params = {
                token: this.token,
                type: this.type,
                words_ids: words_ids,
                study_type: this.study_type
            };
            //console.log(params);
            this.axios
                    .post("/api/course/get_word", params)
                    .then((res) => {
                        //console.log(res);
                        var data = res.data;
                        if (data.status_code == 200) {
                            this.cuociLists = data.data.lists;
                            this.showCuociGongguDialog();
                        } else if (res.data.status_code == 202) {
                        } else {
                            //this.$message(res.data.msg)
                        }
                    })
                    .catch((err) => {
                        //this.loading = false
                    });
        },
        handleClose(id, is_close = 0, close_type = 0) {
            //close_type 1 关闭并测试，2智能复习关闭并到下一个复习
            switch (id) {
                case 1:
                    this.$refs.dialog_jiyi.closeDialog();
                    break;
                case 2:
                    this.$refs.dialog_citing.closeDialog();
                    break;
                case 3:
                    //console.log("dialog_tingxie");
                    this.$refs.dialog_tingxie.closeDialog();
                    break;
                case 4:
                    //console.log("dialog_moxie");
                    this.$refs.dialog_moxie.closeDialog();
                    break;
                case 5:
                    break;
            }
            if (is_close == 1) {
                this.dialogVisible = false;
                this.ciVisible = false;
                this.writeVisible = false;
                this.diVisible = false;
            }
        },
        getTestLog() {
            //获取测试记录
            let than = this;
            this.loading = true;
            let params = {
                token: this.token,
                type: this.type,
                course_id: this.course_id,
                test_type: 4,
            };
            console.log(params);
            this.axios
                    .post("/api/test_center/test_log", params)
                    .then((res) => {
                        //console.log(res);
                        var data = res.data;
                        if (data.status_code == 200) {
                            than.test_log_lists = data.data.lists;
                            than.test_log_count = data.data.count;
                            //console.log(than.test_log_lists);
                            than.refreshEachartData();
                        } else if (res.data.status_code == 202) {
                        } else {
                            //this.$message(res.data.msg)
                        }
                    })
                    .catch((err) => {
                        //this.loading = false
                    });
        },
        refreshEachartData() {
            var date_time = ["2021", "2021", "2021", "2021"];
            var data = [1, 2, 3, 4];
            //console.log("date_time01");
            //console.log(this.test_log_lists);
            this.test_log_lists.forEach((item, index) => {
                //data_time[index] = item.created_at;
                //data[index] = item.inter;
            });
           // console.log("date_time");
            //console.log(date_time);
            //console.log("data");
            //console.log(data);

            //刷新图表数据
            var option = this.myChart.getOption();
            option.xAxis.data = date_time; //时间
            option.series[0].data = data; //数据
            //console.log(option);
            this.myChart.setOption(option);
        },
        showDialog() {
            //显示弹窗
            //console.log("showDialog");
            if (this.type == 1) {
                this.reTestVisible = true;
                //this.$refs.dialog_jiyi.initData(this.test_data);
            } else if (this.type == 5) {
                this.chineseVisible = true; // 智能翻译（中译英）
            } else if (this.type == 2) {
                this.liTestVisible = true; //词听
                //this.$refs.dialog_citing.initData(this.test_data);
            } else if (this.type == 3) {
                this.wrTestVisible = true; //听写
                //this.$refs.dialog_tingxie.initData(this.test_data);
            } else if (this.type == 4) {
                this.diTestVisible = true; //默写
                //this.$refs.dialog_moxie.initData(this.test_data);
            }
        },
        closeDialog() {
            //关闭弹窗
            this.reTestVisible = false;
            this.chineseVisible = false;
            this.liTestVisible = false; //词听
            this.wrTestVisible = false; //听写
            this.diTestVisible = false; //默写
            this.getDanyuan();
        },
        againTest(){
            //关闭弹窗
            this.reTestVisible = false;
            this.chineseVisible = false;
            this.liTestVisible = false; //词听
            this.wrTestVisible = false; //听写
            this.diTestVisible = false; //默写
            setTimeout(()=>{
                this.getWords();
            },300);
        },
        cuociGonggu(errorLists) {
            //console.log("errorLists", errorLists);
            this.getWordsCuociGonggu(errorLists);
        },
        showCuociGongguDialog() {
            //显示弹窗
            if (this.type == 1) {
                this.dialogVisible = true;
            } else if (this.type == 2) {
                this.ciVisible = true; //词听
            } else if (this.type == 3) {
                this.writeVisible = true; //听写
            } else if (this.type == 4) {
                this.diVisible = true; //默写
            }
        },
        backStudy() {
            //返回学习中心
            this.$router.go(-1); //返回上一层
        },
        gotoTest() {
            this.$router.push({name: 'study_study_report'})
        },
        handleCurrentChange(val) {
            //console.log(`当前页: ${val}`);
        },
        setEacharts() {
            this.myChart = echarts.init(document.getElementById("main"));
            let option = {
                xAxis: {
                    type: "category",
                    boundaryGap: "true",
                    axisTick: {
                        show: true,
                        alignWithLabel: true,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#1ca3a3",
                            width: 2,
                        },
                    },
                    axisLabel: {
                        interval: 0,
                    },
                    axisName: {
                        textStyle: {
                            color: "#2f2f2f",
                        },
                    },
                    data: [], //"2021-03-02 11:39", "2021-03-02 11:40"
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    interval: 20,
                    type: "value",
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#ACE8D4",
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#1ca3a3",
                            width: 2,
                        },
                    },
                },
                series: [
                    {
                        data: [], //23, 93
                        type: "line",
                        itemStyle: {
                            normal: {
                                width: 5,
                                height: 5,
                                border: "1px solid #1ca3a3",
                                background: "#fff",
                                lineStyle: {
                                    color: "#1ca3a3",
                                },
                            },
                        },
                    },
                ],
            };
            this.myChart.setOption(option);
        },
    },
    mounted() {
        this.setEacharts();
    },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/test_center.scss";

.selected {
    background-color: #2bc2a1 !important;
}
::v-deep .el-icon-refresh-left {
     font-weight: bold;
     font-size: 18px;
 }
</style>
