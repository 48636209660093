import { render, staticRenderFns } from "./re_unit_test_chinese.vue?vue&type=template&id=bbef6fa4&"
import script from "./re_unit_test_chinese.vue?vue&type=script&lang=js&"
export * from "./re_unit_test_chinese.vue?vue&type=script&lang=js&"
import style0 from "./re_unit_test_chinese.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports